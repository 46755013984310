import { store } from "../redux/store";
import { createNewSalt, getNewToken } from "../redux/auth";
import { hashMd5 } from "./hashMd5";

const identification = async (url, email) => {
  const dispatch = store.dispatch;
  const identification_data = {
    step: "identification",
    email: email,
    actor_type: "classic_user"
  };
  const identification_info = {
    url: `${url}/auth/`,
    req_body: identification_data
  };
  const resp = await dispatch(createNewSalt(identification_info)).unwrap();
  // console.log("temporary_session", resp);
  return resp?.temporary_session;
};

const get_service_token = async (url, temporary_session, md5_password) => {
  const dispatch = store.dispatch;

  const hashSignature = hashMd5(`${md5_password}${temporary_session}`);
  const new_token_info = {
    step: "check_secret",
    actor_type: "classic_user",
    temporary_session: temporary_session,
    password: hashSignature
  };
  const new_token_data = {
    url: `${url}/auth/`,
    req_body: new_token_info
  };

  const respToken = await dispatch(getNewToken(new_token_data)).unwrap();
  return respToken;
  //password - хеш структуры "md5(md5(пароль) + temporary_session)"
};

export const getClassicSession = async (url, email, md5_password) => {
  const temporary_session = await identification(url, email);
  const new_token = await get_service_token(url, temporary_session, md5_password);
  return new_token;
};
