import CryptoJS from "crypto-js";

function aesDecrypt(word, pass) {
  let keys = CryptoJS.enc.Utf8.parse(pass);
  let base64 = CryptoJS.enc.Base64.parse(word);

  let src = CryptoJS.enc.Base64.stringify(base64);

  let decrypt = CryptoJS.AES.decrypt(src, keys, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });

  return decrypt.toString(CryptoJS.enc.Utf8);
}

export const decryptBackup = async (backupTxt, pass) => {
  // const exampleKey = "abcdefghijklmnop";
  // const currentKey = "1234567890123456";
  let actualPass = pass;
  while (actualPass?.length < 16) {
    actualPass = `${actualPass}#`;
  }
  return await fetch(backupTxt)
    .then((res) => {
      return res.text();
    })
    .then((text) => {
      return JSON.parse(aesDecrypt(text, actualPass));
    });
};
