export const checkApt54 = (apt54) => {
  try {
    const outAPT54 = JSON.parse(apt54);
    return outAPT54;
  } catch (e) {
    const check = typeof apt54 === "object";
    if (check) {
      return apt54;
    }
  }
};
