import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    secondary: {
      main: "#757575"
    },
    created: {
      main: "#1d39c4",
      contrastText: "#fff"
    },
    approved: {
      main: "#08979c",
      contrastText: "#fff"
    },
    neutral: {
      main: "#616161",
      contrastText: "#fff"
    }
  },
  typography: {
    title1: {
      margin: "4px 0",
      fonSize: "14px",
      color: "#1976d2"
    },
    subtitle1: {
      fontWeight: "600",
      fontSize: "20px",
      color: "#1976d2"
    },
    subtitle2: {
      fontSize: "16px",
      color: "#1976d2"
    }
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          "& .MuiCardContent-root:last-child": {
            paddingBottom: "16px"
          }
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          position: "relative",
          height: "100%",
          overflow: "hidden",
          width: "100%"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(25, 118, 210, .3)"
          },
          margin: "6px 0"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "16px !important"
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "16px"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "16px"
        }
      },
      variants: [
        {
          props: { size: "round", variant: "outlined" },
          style: {
            padding: "2px 2px",
            minWidth: "50px",
            height: "50px",
            borderRadius: "25px"
          }
        },
        {
          props: { size: "round", variant: "contained" },
          style: {
            padding: "2px 2px",
            minWidth: "50px",
            height: "50px",
            borderRadius: "25px"
          }
        }
      ]
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          top: "8px",
          left: "8px !important",
          maxWidth: "calc(100% - 16px) !important"
        }
      }
    }
  }
});
