import { useFormContext } from "react-hook-form";

import { TextField, ListItem, Typography, IconButton } from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SaveIcon from "@mui/icons-material/Save";

export const FormTextField = ({
  fieldLabel,
  fieldName,
  maxLength,
  isVisible,
  callbackVisibility,
  isDisabled,
  isAuto,
  isMulti,
  isCountChar,
  isOfficeCard,
  callbackOfficeCard,
  type,
  isRequired,
  placeholder
}) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors, isDirty }
  } = useFormContext();

  const checkIsPassword = fieldName.includes("password");

  return (
    <>
      <Typography variant="subtitle2">{fieldLabel}</Typography>
      <ListItem key={`${fieldName}_backup`} disablePadding sx={{ position: "relative" }}>
        <TextField
          multiline={isMulti}
          minRows={1}
          maxRows={isMulti ? undefined : 6}
          autoFocus={isAuto}
          disabled={isDisabled}
          // label={fieldLabel(item)}
          name={`${fieldName}`}
          {...register(`${fieldName}`)}
          onBlur={callbackOfficeCard}
          // required={isRequired ? isRequired : true}
          type={isVisible ? type || "text" : "password"}
          inputProps={{ maxLength: maxLength, placeholder: !!placeholder ? placeholder : "" }}
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <>
                {/* <IconButton
                  onClick={callbackOfficeCard}
                  sx={{
                    visibility: isOfficeCard && isDirty ? "visible" : "hidden",
                    padding: "2px 4px"
                  }}
                  color="primary"
                >
                  <SaveIcon />
                </IconButton> */}

                {checkIsPassword && (
                  <IconButton onClick={() => callbackVisibility((prev) => !prev)}>
                    {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                )}
              </>
            )
          }}
          error={errors?.[`${fieldName}`]}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: `12px`
              }
            }
          }}
          variant="standard"
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            bottom: "-24px",
            left: "0",
            fontSize: "14px"
          }}
        >
          <div style={{ color: "#d32f2f" }}>{errors?.[`${fieldName}`]?.message}</div>
          {(checkIsPassword || isCountChar) && (
            <div>
              {watch(fieldName)?.length} / {maxLength}
            </div>
          )}
        </div>
      </ListItem>
    </>
  );
};
