import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setRegData } from "../../../redux/scaner";

import { Card, CardHeader, CardContent, Button } from "@mui/material";
import { ListItem, ListItemIcon, ListItemText, FormLabel, Typography } from "@mui/material";
import { CloseButton } from "../../../components/CloseButton/CloseButton";
import { DialogWindow } from "../../../components/DialogWindow/DialogWindow";
import { BackupUploader } from "../../../components/BackupUploader/BackupUploader";
import { SubGroup } from "../../../components/Subgroup/Subgroup";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LoginIcon from "@mui/icons-material/Login";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import PersonIcon from "@mui/icons-material/Person";
import { scannerType } from "../../../utils/constants";

export const AddAccountModal = ({
  handleCloseDialog,
  handleOpenRegDialog,
  handleOpenClassicModal,
  handleSetBackupFile
}) => {
  const dispatch = useDispatch();
  const handleEcosystemAcc = () => {
    handleOpenRegDialog(scannerType.reg);
  };

  const handleClassicAccountReg = () => {
    // handleOpenRegDialog(scannerType.classic_reg);
    handleOpenClassicModal(true);
  };

  const handleClassicAccountSignIn = () => {
    // handleOpenRegDialog(scannerType.classic_sign_in);
    handleOpenClassicModal(false);
  };

  useEffect(
    () => () => {
      dispatch(setRegData(null));
    },
    []
  );

  return (
    <>
      <Card elevation={0}>
        <CardHeader
          title={<Typography variant="subtitle1">Add an account</Typography>}
          action={<CloseButton handleCallback={handleCloseDialog} />}
          sx={{ paddingBottom: "0" }}
        />
        <CardContent sx={{ paddingTop: "0" }}>
          <div style={{ margin: "8px 0 8px 0" }}>
            <SubGroup label={<strong>Sign up</strong>} />
          </div>

          <Typography variant="subtitle2" sx={{ paddingLeft: "16px" }} color="gray">
            Scan QR code for registration
          </Typography>
          <Button variant="outlined" fullWidth size="small" onClick={handleEcosystemAcc} sx={{ marginBottom: "8px" }}>
            <ListItem sx={{ padding: "3px 6px" }}>
              <ListItemIcon sx={{ minWidth: "36px" }}>
                <QrCodeScannerIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Create an Ecosystem account</ListItemText>
            </ListItem>
          </Button>
          <Typography variant="subtitle2" sx={{ paddingLeft: "16px" }} color="gray">
            Create a new account with a email and password
          </Typography>
          <Button variant="outlined" fullWidth size="small" onClick={handleClassicAccountReg}>
            <ListItem sx={{ padding: "3px 6px" }}>
              <ListItemIcon sx={{ minWidth: "36px" }}>
                <PersonAddIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Create a classic account</ListItemText>
            </ListItem>
          </Button>
          <div style={{ margin: "16px 0 8px 0" }}>
            <SubGroup label={<strong>Sign in</strong>} />
          </div>

          <Typography variant="subtitle2" sx={{ paddingLeft: "16px" }} color="gray">
            Import an account using a backup file
          </Typography>
          <BackupUploader handleSetBackupFile={handleSetBackupFile} />

          <Typography variant="subtitle2" sx={{ marginTop: "8px", paddingLeft: "16px" }} color="gray">
            Add an existing account by email and password
          </Typography>
          <Button variant="outlined" fullWidth size="small" onClick={handleClassicAccountSignIn}>
            <ListItem sx={{ padding: "3px 6px" }}>
              <ListItemIcon sx={{ minWidth: "36px" }}>
                <PersonIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Restore a classic account</ListItemText>
            </ListItem>
          </Button>
        </CardContent>
      </Card>
    </>
  );
};
