import { store } from "../../redux/store";
import { setType, setMessage, setIsOpen } from "../../redux/notify";

export const openNotify = (type, mes) => {
  // console.log(type, mes);
  const dispatch = store.dispatch;

  dispatch(setIsOpen(true));
  dispatch(setType(type));
  dispatch(setMessage(mes));
};
