import { createSlice } from "@reduxjs/toolkit";

export const backHandlerSlice = createSlice({
  name: "backHandler",
  initialState: {
    type: "",
    message: "",
    isOpen: false
  },
  reducers: {
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },

    setType: (state, action) => {
      state.type = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    }
  }
});
export const { setIsOpen, setType, setMessage } = backHandlerSlice.actions;
export default backHandlerSlice.reducer;
