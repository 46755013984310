import { sign_in_gen } from "./sign_in_gen";
import stringify from "json-stable-stringify";
import dayjs from "dayjs";

export const createRootAPT54 = async (values) => {
  const expiration = dayjs(new Date(Date.now() + 86400e3 * 14)).format("YYYY-MM-DD HH:mm:ss");
  // console.log("values?.user_data", stringify(values?.user_data, { space: " " }));
  // const signature_string = `${stringify(values?.user_data)}${expiration}`;
  const signature_string = JSON.stringify(values?.user_data).replaceAll(",", ", ").replaceAll('":', '": ') + expiration;
  // console.log("signature_string", signature_string.replaceAll(",", ", ").replaceAll('":', '": '));
  // console.log("signature_string", signature_string);
  const signature = (await sign_in_gen(signature_string, values?.user_priv_key)).signature;

  const apt54 = {
    apt54: {
      signature: signature,
      expiration: expiration,
      user_data: values?.user_data
    },

    create_session: true
  };

  return apt54;
};
