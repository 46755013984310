import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import VConsole from "vconsole";

export const DebugComponent = () => {
  const { isDebugMode } = useSelector((state) => state.settings);

  const debugRef = useRef(null);

  const turnOn = () => {
    const vConsole = new VConsole({ theme: "dark" });
    debugRef.current = vConsole;
    const icon = `<div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#1976d2" d="M14 12h-4v-2h4m0 6h-4v-2h4m6-6h-2.81a5.985 5.985 0 0 0-1.82-1.96L17 4.41L15.59 3l-2.17 2.17a6.002 6.002 0 0 0-2.83 0L8.41 3L7 4.41l1.62 1.63C7.88 6.55 7.26 7.22 6.81 8H4v2h2.09c-.05.33-.09.66-.09 1v1H4v2h2v1c0 .34.04.67.09 1H4v2h2.81c1.04 1.79 2.97 3 5.19 3s4.15-1.21 5.19-3H20v-2h-2.09c.05-.33.09-.66.09-1v-1h2v-2h-2v-1c0-.34-.04-.67-.09-1H20V8Z"/></svg></div>`;

    const debugComponent = document.querySelector(".vc-switch");
    debugComponent.innerHTML = icon;
  };

  const turnOff = () => {
    if (!!debugRef.current) {
      debugRef.current.destroy();
    }
  };

  useEffect(() => {
    if (isDebugMode) {
      turnOn();
    } else {
      turnOff();
    }
  }, [isDebugMode]);

  return <></>;
};
