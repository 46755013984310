import dayjs from "dayjs";
import { front_date_with_time } from "./constants";

export const createAtTimeParse = (created, format) => {
  try {
    const timeZoneString = dayjs.tz.guess();

    const date = dayjs(`${created} UTC`)
      .tz(timeZoneString)
      .format(format || front_date_with_time);

    return date;
  } catch (e) {
    console.log(e);
    return created;
  }
};
