import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export const WrongQR = () => {
  return (
    <div style={{ marginTop: "36px", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <WarningAmberIcon color="warning" sx={{ marginRight: "16px", fontSize: "30px" }} />
        <Typography color="warning" variant="subtitle1">
          Unsupported QR code
        </Typography>
      </div>
      <Typography
        color="warning"
        variant="subtitle2"
        sx={{ marginTop: "16px", padding: "0 24px", textAlign: "center" }}
      >
        You have scanned an unsupported QR code. Please, try to get the latest QR code on a web service.
      </Typography>
    </div>
  );
};
