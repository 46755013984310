import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

export const SignInText = (props) => {
  const { first_name, last_name, email } = props;
  return (
    <div style={{ marginTop: "36px", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <QrCodeScannerIcon color="primary" sx={{ marginRight: "16px", fontSize: "30px" }} />
        <Typography color="primary" variant="subtitle1">
          Sign in
        </Typography>
      </div>
      <Typography
        color="primary"
        variant="subtitle2"
        sx={{ marginTop: "16px", padding: "0 24px", textAlign: "center" }}
      >
        Scan the QR code on your service and log in with account {first_name} {last_name} ({email}).
      </Typography>
    </div>
  );
};
