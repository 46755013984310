import { checkApt54 } from "../../utils/checkAPT54";
import { sign_in_gen } from "../../utils/sign_in_gen";

const old_url = "https://auth.p2.54origins.com";
const prod_url = "https://auth.p3.54origins.com";
const test_url = "https://auth.09releasetest.54origins.com";
const old_name = "NewProdBiom";
const test_name = "09releasetest";
const prod_name = "p3ProdBiom";

const test_biom_uuid = "e10ceb61-2b46-4837-9a83-6ee45b718672";

export const parseOldBackup = (backup) => {
  try {
    // console.log("backup", backup);
    const apt54 = checkApt54(backup?.authority?.apt54);
    const user_data = apt54?.user_data;
    const uinfo = user_data?.uinfo;
    const biom_data = {
      biom_domain: backup?.biom?.domain === old_url ? prod_url : backup?.biom?.domain,
      biom_name: backup?.biom?.name === old_name ? prod_name : backup?.biom?.name,
      biom_uuid: backup?.biom?.uuid
    };

    const account_data = {
      user_uuid: backup?.authority?.uuid,
      user_priv_key: backup?.authority?.private_key,
      actor_type: user_data?.actor_type,
      created: user_data?.created,
      root: !!backup?.user_info?.is_root,
      email: uinfo?.email,
      first_name: uinfo?.first_name,
      last_name: uinfo?.last_name,
      groups: Array.isArray(uinfo?.groups) ? uinfo?.groups : [],
      biom: {
        ...biom_data
      }
    };
    return account_data;
  } catch (e) {
    console.log("e", e);
    return null;
  }

  // console.log("account_data", account_data);
};
