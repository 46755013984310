import { secp256k1 } from "@noble/curves/secp256k1";
import { sha256 } from "@noble/hashes/sha256";

function i2hex(i) {
  return ("0" + i.toString(16)).slice(-2);
}

export const keyGen = async (salt) => {
  const saltHash = sha256(salt); // salt hash from QR
  const priv = secp256k1.utils.randomPrivateKey(); // generating a private key (Uint8Array)
  const privHash = Array.from(priv).map(i2hex).join(""); // converting the private key to a 16-bit hash
  const pub = secp256k1.getPublicKey(priv, false); // getting a public key using a private key, parameter false - getting a uint8array of 65 elements (DER-format)
  const pubHash = Array.from(pub).map(i2hex).join(""); // converting the private key to a 16-bit hash, 65 elem * 2 = 130 characters of a 16-bit hash
  const sig = secp256k1.sign(saltHash, privHash); // generating a signature from a salt and a private key
  const sigHash = sig.toDERHex(); // converting the signature to a 16-bit hash
  return { priv: privHash, pub: pubHash, signature: sigHash };
};
