import * as React from "react";

export function useAddToHomeScreenPrompt() {
  const [promptable, setPromptable] = React.useState(null);

  const [isInstalled, setIsInstalled] = React.useState(false);

  const promptToInstall = () => {
    if (promptable) {
      return promptable.prompt();
    }
    return Promise.reject(new Error('Tried installing before browser sent "beforeinstallprompt" event'));
  };

  React.useEffect(() => {
    const ready = (e) => {
      e.preventDefault();
      setPromptable(e);
    };

    window.addEventListener("beforeinstallprompt", ready);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);

  React.useEffect(() => {
    const onInstall = () => {
      setIsInstalled(true);
    };

    window.addEventListener("appinstalled", onInstall);

    return () => {
      window.removeEventListener("appinstalled", onInstall);
    };
  }, []);

  return [promptable, promptToInstall, isInstalled];
}
