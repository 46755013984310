import { store } from "../../redux/store";
import { createNewUser, aboutInfoRequest, addNewUser } from "../../redux/auth";
import { getClassicSession } from "../../utils/getClassicSession";
import { hashMd5 } from "../../utils/hashMd5";
import { saveToken } from "../../utils/saveToken";

export const handleClassicReg = async (values) => {
  const dispatch = store.dispatch;
  try {
    const serviceData = {
      reg_url: `${values?.service_url}/reg/`,
      about_url: `${values?.service_url}/about/`,
      auth_url: `${values?.service_url}`
    };
    const req_body = {
      actor_type: "classic_user",
      email: values.email,
      password: values?.password,
      password_confirmation: values?.password,
      uinfo: {
        first_name: values.first_name,
        last_name: values.last_name
      }
    };
    const newUSerData = {
      url: serviceData.reg_url,
      req_body: req_body
    };

    const resp_actor = await dispatch(createNewUser(newUSerData)).unwrap();
    console.log("resp_actor", resp_actor);
    const about_data = await dispatch(aboutInfoRequest({ url: serviceData?.about_url })).unwrap();
    // console.log("about_data", about_data);
    // const temporary_session = await identification(serviceData.auth_url, values?.email);

    // const resp_service_token = await get_service_token(serviceData.auth_url, temporary_session, req_body.password);
    const md5_password = hashMd5(values?.password);
    const resp_service_token = await getClassicSession(serviceData.auth_url, values?.email, md5_password);
    // console.log("get_service_token", resp_service_token);
    const uinfo = resp_actor?.uinfo;
    const newCurrentUserData = {
      user_uuid: resp_actor?.uuid,
      user_priv_key: null,
      password: md5_password,
      actor_type: resp_actor.actor_type,
      created: resp_actor?.created,
      root: false,
      email: uinfo?.email,
      first_name: uinfo?.first_name,
      last_name: uinfo?.last_name,
      groups: Array.isArray(uinfo?.groups) ? uinfo?.groups : [],
      biom: {
        biom_uuid: about_data?.biom_uuid,
        biom_domain: about_data?.biom_domain,
        biom_name: about_data?.biom_name
      }
      // services_uuid: [about_data?.service_uuid]
    };
    saveToken(
      resp_service_token?.session_token,
      resp_service_token?.expiration,
      resp_actor?.uuid,
      about_data?.service_uuid
    );
    dispatch(addNewUser(newCurrentUserData));
    return newCurrentUserData;
  } catch (e) {
    console.log("e", e);
  }
};
