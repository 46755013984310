import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { setSelectedUserInfo } from "../../../redux/auth.js";

import { Button, Typography } from "@mui/material";

import { TitleBlock } from "./TitleBlock.jsx";
import { InfoBlock } from "./InfoBlock";
import { ActionsBlock } from "./ActionsBlock.jsx";
import { ModalPasswordError } from "../../../components/ModalPasswordError/ModalPasswordError.jsx";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import "../index.css";

export const AccountInfo = (props) => {
  // console.log("props", props);
  const dispatch = useDispatch();

  const { selected_user_info, current_user } = useSelector((state) => state.auth);

  const data = selected_user_info || current_user;

  useEffect(
    () => () => {
      dispatch(setSelectedUserInfo(null));
    },
    []
  );

  const checkIsActiveUser = data?.user_uuid === current_user?.user_uuid;

  return (
    <div
      style={{
        marginTop: "16px",
        padding: "16px 16px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "16px"
      }}
    >
      <TitleBlock {...data} checkIsActiveUser={checkIsActiveUser} />
      <InfoBlock {...data} />
      <ActionsBlock {...data} {...props} checkIsActiveUser={checkIsActiveUser} />
      <ModalPasswordError />
    </div>
  );
};
