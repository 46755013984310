export const addAccountModalStyle = {
  position: "absolute",
  bottom: "0",
  left: "0",
  overflow: "auto",
  // width: "100vw",
  width: "100vw",
  height: "520px",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "16px 16px 0 0",
  boxShadow: 24,
  p: 0
};
