import { CardActionArea, Card, CardContent, Typography, Button } from "@mui/material";
import { scannerType } from "../../utils/constants";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export const NoAccounts = ({ callback }) => {
  return (
    <>
      <CardActionArea sx={{ marginBottom: "16px" }} onClick={callback}>
        <Card elevation={4} sx={{ height: "120px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CardContent sx={{ textAlign: "center" }}>
            <AccountCircleIcon sx={{ marginBottom: "8px", fontSize: "48px" }} color="primary" />
            <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
              Sign up \ Sign in
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
    </>
  );
};
