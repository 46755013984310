import { createSlice } from "@reduxjs/toolkit";
import { getStorageValue } from "../utils/getStorageValue";
import { appRoutes } from "../utils/constants";

const initialState = {
  currentPage: "",
  showAllAccounts: true,
  showAllOffices: !!getStorageValue("lockDoorsPage") ? !getStorageValue("lockDoorsPage") : true,
  lockDoorsPage: !!getStorageValue("lockDoorsPage")
};

export const switcherSlice = createSlice({
  name: "switcher",
  initialState: {
    ...initialState
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
      if (action.payload === appRoutes.relays.path.slice(1)) {
        state.showAllOffices = state.lockDoorsPage ? (state.showAllOffices = false) : (state.showAllOffices = true);
      }
      if (action.payload === appRoutes.accounts.path.slice(1)) {
        state.showAllAccounts = true;
      }
    },
    setLockDoorsPage: (state, action) => {
      state.lockDoorsPage = action.payload;
      localStorage.setItem("lockDoorsPage", action.payload);
    },
    setShowAllAccounts: (state, action) => {
      state.showAllAccounts = action.payload;
    },
    setShowAllOffices: (state, action) => {
      state.showAllOffices = action.payload;
    }
  }
});

export const { setShowAllAccounts, setShowAllOffices, setCurrentPage, setLockDoorsPage } = switcherSlice.actions;

export default switcherSlice.reducer;
