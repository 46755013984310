import CryptoJS from "crypto-js";

function aesEncrypt(word, pass) {
  let text = CryptoJS.enc.Utf8.parse(word);
  let key = CryptoJS.enc.Utf8.parse(pass);
  let encrypted = CryptoJS.AES.encrypt(text, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
  let finalContent = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  return finalContent;
}

export const encryptBackup = async (backupTxt, pass) => {
  // const exampleKey = "abcdefghijklmnop";
  // const currentKey = "1234567890123456";
  let actualPass = pass;
  while (actualPass?.length < 16) {
    actualPass = `${actualPass}#`;
  }
  return aesEncrypt(backupTxt, actualPass);
};
