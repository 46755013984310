import { scanImageData } from "zbar.wasm";
import React, { createRef, useLayoutEffect, useRef } from "react";
import { Box } from "@mui/material";

const SCANS_PER_MS = 800;

export const ZBarScanner = React.memo(({ handleIsScan, resetCam }) => {
  const canvasRef = createRef();
  const playerRef = createRef();
  const mediaStreamRef = useRef();

  const initPlayer = async () => {
    const player = playerRef.current;
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        facingMode: "environment",
        width: { max: 640 },
        height: { max: 640 }
      }
    });
    mediaStreamRef.current = mediaStream;
    player.srcObject = await mediaStream;
    await new Promise((r) => {
      player.onloadedmetadata = r;
    });
  };

  const renderCode = (symbols) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;
    ctx.clearRect(0, 0, width, height);
    for (let i = 0; i < symbols.length; ++i) {
      const sym = symbols[i];
      const data = sym.decode();
      handleIsScan(data);
      stopCam();
    }
  };

  const scanCode = async () => {
    const player = playerRef.current;
    const canvas = canvasRef.current;

    if (player && canvas) {
      let width = player.videoWidth;
      let height = player.videoHeight;

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(player, 0, 0, width, height);
      const imgData = ctx.getImageData(0, 0, width, height);
      const res = await scanImageData(imgData);
      renderCode(res);
    }
  };

  const sleep = (ms) =>
    new Promise((r) => {
      setTimeout(r, ms);
    });

  const main = async () => {
    try {
      await initPlayer();
      while (true) {
        await scanCode();
        await sleep(SCANS_PER_MS);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useLayoutEffect(() => {
    // console.log("mount", playerRef.current, canvasRef);
    if (canvasRef?.current && playerRef?.current) {
      main();
    }
  }, [canvasRef, playerRef]);

  // useEffect(() => {
  //   if (mediaStreamRef.current) {
  //     // console.log("unmount");
  //     mediaStreamRef.current.getTracks().forEach((track) => {
  //       console.log("track", track);
  //       // track.start();
  //     });
  //   }
  // }, [resetCam]);

  // const resetCamera = async () => {
  //   await navigator.mediaDevices.getTracks().forEach((track) => track.stop());
  // };

  const stopCam = () => {
    if (mediaStreamRef.current) {
      // console.log("unmount");
      mediaStreamRef.current.getTracks().forEach((track) => {
        // console.log("track", track);
        track.stop();
      });
    }
  };

  useLayoutEffect(() => {
    return () => {
      stopCam();
    };
  }, []);

  return (
    <>
      <Box
        className="preloader-scan"
        sx={{
          position: "relative",
          "& canvas": {
            display: "block",
            position: "absolute",
            top: 0,
            left: 0,
            width: "300px",
            height: "300px"
          },
          "& video": {
            display: "block"
          }
        }}
      >
        <canvas ref={canvasRef} />

        <video
          style={{ borderRadius: "16px" }}
          id="video"
          ref={playerRef}
          autoPlay
          muted
          controls={false}
          playsInline
          width="100%"
        />
      </Box>
    </>
  );
});
