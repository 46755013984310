import { Divider, Typography, Button } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const ExpandTitle = (props) => {
  const { label, labelHide, isOpen, handleShowMore } = props;
  return (
    <Button onClick={handleShowMore} fullWidth sx={{ display: "flex", justifyContent: "center" }}>
      <Divider
        sx={{
          "&:before": { width: 50 },
          "&:after": { width: 50 }
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {!isOpen ? label : labelHide}
          <ExpandMoreIcon sx={{ transition: ".3s", transform: !isOpen ? "rotate(0deg)" : "rotate(180deg)" }} />
        </div>
      </Divider>
    </Button>
  );
};
