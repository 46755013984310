import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const CloseButton = ({ handleCallback }) => {
  const handleClick = () => {
    handleCallback(false);
  };
  return (
    <IconButton onClick={handleClick}>
      <CloseIcon />
    </IconButton>
  );
};
