import { store } from "../redux/store";

import { getEcosystemSession } from "./getEcosystemSession";
import { getClassicSession } from "./getClassicSession";
import { getActorData, updateAccountInfo } from "../redux/auth";

import dayjs from "dayjs";
import { front_date_with_time } from "./constants";
import { openNotify } from "../components/OpenNotify/openNotify";

export const handleUpdateAccountInfo = async (account, newMd5Password) => {
  const dispatch = store.dispatch;
  const actorType = account?.actor_type;

  const url = account?.biom?.biom_domain;
  const user_uuid = account?.user_uuid;
  const email = account?.email;
  const password = newMd5Password || account?.password;
  const user_priv_key = account?.user_priv_key;
  try {
    const resp_token =
      actorType === "classic_user"
        ? await getClassicSession(url, email, password)
        : await getEcosystemSession(url, user_uuid, user_priv_key);

    const req_data = {
      url: url,
      token: resp_token?.session_token
    };

    const respUpdate = await dispatch(getActorData(req_data)).unwrap();
    const user_data = respUpdate?.actor;
    const uinfo = user_data?.uinfo;
    const newData = {
      user_uuid: user_data?.uuid,
      actor_type: user_data?.actor_type,
      is_admin: user_data?.is_admin,
      root: user_data?.root,
      root_perms_signature: user_data?.root_perms_signature,
      email: uinfo?.email,
      first_name: uinfo?.first_name,
      last_name: uinfo?.last_name,
      internal_user: uinfo?.internal_user,
      last_update: dayjs(new Date()).format(front_date_with_time),
      password: newMd5Password || account?.password
    };

    dispatch(updateAccountInfo(newData));
    openNotify("success", "Account information has been successfully updated");
  } catch (e) {
    console.log("error", e);
  }
};
