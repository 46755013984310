// -------- reac, lib ----------
import { useState, useEffect } from "react";

// -------- components ----------

import FullScreenDialog from "../../components/FullScreenDialog/FullScreenDialog";

import { AccountInfo } from "./AccountInfo/AccountInfo";

import { ScannerComponent } from "../../components/Scaner/ScannerComponent";

import { ModalPasswordError } from "../../components/ModalPasswordError/ModalPasswordError";
// -------- icons ----------

// -------- const, utils ----------

import { scannerType } from "../../utils/constants";

export const Account = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentScannerType, setCurrentScannerType] = useState(scannerType.sign_in);
  // const [showAllAccounts, setShowAllAccounts] = useState(false);

  const handleOpenDialog = (scanType) => {
    setCurrentScannerType(scanType);
    setOpenDialog(true);
  };

  const scannerComponent = <ScannerComponent type={currentScannerType} callbackClose={setOpenDialog} />;

  return (
    <>
      <AccountInfo handleOpenSignInDialog={handleOpenDialog} />
      <ModalPasswordError />
      <FullScreenDialog children={scannerComponent} open={openDialog} setOpen={setOpenDialog} />
    </>
  );
};
