import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, CircularProgress } from "@mui/material";

export const ScannerFocus = ({ isLoading, isScan, handleStartScan }) => {
  const scanColor = !isScan ? "green" : "transparent";
  return (
    <div
      className="scan_line_wrapper"
      style={{
        position: "absolute",
        zIndex: "1000",
        width: "calc(100% - 24px)",
        height: "calc(100% - 24px)",
        backgroundColor: isScan ? "black" : "transparent",
        borderRadius: "16px"
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          padding: "12px 12px"
        }}
      >
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", position: "relative" }}>
          <div
            style={{
              width: "50px",
              height: "50px",
              borderTop: `3px solid ${scanColor}`,
              borderLeft: `3px solid ${scanColor}`,
              borderRadius: "16px 0 0 0"
            }}
          ></div>
          <div
            style={{
              width: "50px",
              height: "50px",
              borderTop: `3px solid ${scanColor}`,
              borderRight: `3px solid ${scanColor}`,
              borderRadius: "0 16px 0 0"
            }}
          ></div>
        </div>
        <div style={{ position: "relative", width: "100%", display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              width: "50px",
              height: "50px",
              borderBottom: `3px solid ${scanColor}`,
              borderLeft: `3px solid ${scanColor}`,
              borderRadius: "0 0 0 16px"
            }}
          ></div>
          <div
            style={{
              width: "50px",
              height: "50px",
              borderBottom: `3px solid ${scanColor}`,
              borderRight: `3px solid ${scanColor}`,
              borderRadius: "0 0 16px 0"
            }}
          ></div>
          <div
            style={{
              width: "100%",

              position: "absolute",
              bottom: "-30px",
              textAlign: "center"
            }}
          >
            <span
              style={{
                padding: "2px 6px",
                borderRadius: "16px",
                background: !isScan ? "white" : "transparent",
                color: !isScan ? "gray" : "transparent",
                textAlign: "center",
                fontWeight: "500",
                fontSize: "14px"
              }}
            >
              Place the QR in the frame
            </span>

            {/* Сканировать */}
            {/* {t("SALE_CARTAME.SCAN")} */}
          </div>
        </div>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: "calc(50% - 20px)",
              left: "calc(50% - 20px)"
            }}
          >
            <CircularProgress />
          </div>
        )}
        {isScan && (
          <div
            style={{
              position: "absolute",
              top: "calc(50% - 25px)",
              left: "calc(50% - 25px)",
              borderRadius: "25px",
              backgroundColor: "white"
            }}
          >
            <Button variant="outlined" size="round" onClick={handleStartScan}>
              <RefreshIcon />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
