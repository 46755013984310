import { Box } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const style = {
  position: "absolute",
  bottom: "0",
  left: "0",
  overflow: "auto",
  // width: "100vw",
  width: "100vw",
  height: "280px",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "16px 16px 0 0",
  boxShadow: 24,
  p: 0
};

export const DialogWindow = ({ children, open, setOpen, styleProps }) => {
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Box sx={styleProps || style}>{children || "modal content"}</Box>
      </Fade>
    </Modal>
  );
};
