import { useSelector, useDispatch } from "react-redux";
import { setSwIsUpdated } from "../../redux/notify";
import { useEffect, useState } from "react";
import { Alert, Snackbar, Container, Button, Paper } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export const UpdateNotify = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { serviceWorkerRegistration, serviceWorkerUpdated } = useSelector((state) => state.notify);

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration?.waiting;
    setLoading(true);
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  };

  const handleClose = () => {
    dispatch(setSwIsUpdated(false));
  };

  return (
    <Snackbar
      open={serviceWorkerUpdated}
      // autoHideDuration={4000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{ zIndex: 15000 }}
      // onClick={handleClose}
      onClose={handleClose}
    >
      <Container maxWidth="sm" disableGutters>
        <Paper elevation={3} sx={{}}>
          <Alert
            // icon={false}
            onClose={handleClose}
            severity={"info"}
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              "& .MuiAlert-message": { width: "calc(100% - 64px)" }
            }}
          >
            <div> A new update is available</div>
            <div>
              <LoadingButton
                loading={loading}
                fullWidth
                variant="contained"
                onClick={updateServiceWorker}
                sx={{ marginTop: "8px" }}
              >
                Update the app
              </LoadingButton>
            </div>
          </Alert>
        </Paper>
      </Container>
    </Snackbar>
  );
};
