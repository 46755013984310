import { useSelector } from "react-redux";
import { Avatar, Chip } from "@mui/material";

export const TitleBlock = (props) => {
  const { first_name, last_name, email } = props;

  return (
    <div style={{ position: "relative", marginBottom: "16px", display: "flex", alignItems: "center" }}>
      {props?.root && (
        <Chip
          label={<strong>Root</strong>}
          color="primary"
          size="small"
          sx={{ position: "absolute", top: "-8px", right: "-8px" }}
        />
      )}
      {props?.is_admin && (
        <Chip
          label={<strong>Admin</strong>}
          color="info"
          size="small"
          sx={{ position: "absolute", top: props?.root ? "24px" : "-8px", right: "-8px" }}
        />
      )}

      <div style={{ display: "inline-block" }}>
        <Avatar sx={{ background: "#1976d2", textAlign: "center" }}>
          {!!first_name ? first_name[0].toUpperCase() : ""}
          {!!last_name ? last_name[0].toUpperCase() : ""}
        </Avatar>
      </div>

      <div style={{ display: "inline-block", width: "100%" }}>
        <div style={{ marginBottom: "4px", fontSize: "16px", textAlign: "center" }}>
          {first_name} {last_name}
        </div>
        <div style={{ fontSize: "14px", textAlign: "center" }}> {email}</div>
      </div>
    </div>
  );
};
